<template>
    <div :class="{ 'desktop-nav': true, 'scrolled': isScrolled, 'w-100': true }" style="z-index: 99; top: 0;">
        <div style="padding:.5em 1em;background:#1F9BCE">
            <div class="row align-items-center" style="gap:10px 0">
                <div class="col-lg">
                    <div class="d-flex align-items-center flex-wrap" style="gap:10px 30px">
                        <router-link to="/homepage/desktop" class="home-link">
                            <img src="@/static/logo.png" style="max-width:200px" />
                        </router-link>
                        <router-link to="/homepage" class="home-link">
                            <div class="text-white h4 mb-0">Home</div>
                        </router-link> 
                        <router-link to="/shelter" class="home-link">
                            <div class="text-white h4 mb-0">Shelter</div>
                        </router-link> 
                        <router-link to="/vet/home" class="home-link">
                            <div class="text-white h4 mb-0">Vet</div>
                        </router-link> 
                        <router-link to="/training" class="home-link">
                            <div class="text-white h4 mb-0">Trainer</div>
                        </router-link> 
                        <router-link to="/walker/list" class="home-link">
                            <div class="text-white h4 mb-0">Walker</div>
                        </router-link> 
                    </div>
                </div>
                <div class="col-lg d-flex justify-content-end">
                    <ul class="nav navbar-nav align-items-center ms-auto" style="flex-direction:row;gap:30px"> 
                        <li class="nav-item dropdown dropdown-user" v-if="user">
                            <a class="nav-link dropdown-toggle dropdown-user-link d-flex align-items-center" id="dropdown-user" href="#"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="user-nav d-sm-flex d-none text-right me-3 text-white" style="max-width:150px;text-wrap: wrap;">
                                    <div>
                                        <span class="user-name fw-bolder">Your Country</span> 
                                        <div class="" style=""> 
                                            {{country}}
                                        </div>
                                    </div>
                                </div> 
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user"> 
                                <a @click="changeRegion(item.slug)" class="dropdown-item" v-for="item in countries">
                                    <img :src="item.flag" 
                                        style="height:15px;width:20px;object-fit: cover;margin-right: .5em;" />
                                    <span>{{ item.name }}</span>
                                </a>
                            </div>
                        </li> 
                        <li class="nav-item dropdown dropdown-user" v-if="user">
                            <a class="nav-link dropdown-toggle dropdown-user-link d-flex align-items-center" id="dropdown-user" href="#"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="user-nav d-sm-flex d-none text-right me-3 text-white" style="max-width:150px;text-wrap: wrap;">
                                    <span class="user-name fw-bolder">{{user.user_name}}</span> 
                                </div>
                                <span class="avatar">
                                    <img class="round bg-white"
                                        @error="handleImageError"
                                        :src="user.user_picture"
                                        style="object-fit: cover;border:2px solid #FFAD32" alt="avatar" height="50" width="50">
                                    <span class="avatar-status-online"></span>
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user">
                                <div class="row" style="width: 500px;gap:10px 0">
                                    <div class="col-7">
                                        <div class="p-1 h-100 w-100">
                                            <div class="fw-bold" style="margin-bottom: .5em;">Current Role</div>
                                            <div class="d-flex flex-wrap" style="gap:10px">
                                                <div @click="changeRole(item.slug)"  :class="`btn btn-sm btn-${item.slug == currentRole.slug ? 'primary' : 'secondary'} flex-fill`" :key="item.slug" v-for="item in roles">{{item.name}}</div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="card h-100 d-flex flex-column">
                                            <router-link to="/chats" class="dropdown-item flex-fill d-flex align-items-center" href=" ">
                                                <i class="me-50" data-feather="power"></i> Chat
                                            </router-link>
                                            <router-link to="/profile" class="dropdown-item flex-fill d-flex align-items-center" href=" ">
                                                <i class="me-50" data-feather="power"></i> Profile
                                            </router-link>
                                            <router-link to="/activities" class="dropdown-item flex-fill d-flex align-items-center" href=" ">
                                                <i class="me-50" data-feather="power"></i> Activities
                                            </router-link>
                                            <router-link to="/settings" class="dropdown-item flex-fill d-flex align-items-center" href=" ">
                                                <i class="me-50" data-feather="power"></i> Settings
                                            </router-link>
                                            <a @click="logout()" class="dropdown-item flex-fill d-flex align-items-center" href=" ">
                                                <i class="me-50" data-feather="power"></i> Logout
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-else>
                            <router-link to="/login">
                                <div class="h5 fw-bolder text-white rounded-pill"
                                    style="background-color: #005071;border: 1px solid #FFAD32;padding:.75em 2em">
                                    Sign In
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="bg-white" style="padding:.08em 0"></div> 
    </div>
</template>

<script>
import store from '@/store'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default {
    computed: {
        routeName() {
            return this.$route.meta.title
        },
        routeTitle() {
            return this.$route.meta.title
        },
        user() {
            return store.state.auth.USER
        },
        currentRole(){
            return this.roles.find(item => (item.slug == this.user.user_role))
        },
        user() {
            return store.state.auth.USER
        },
        country() {
            return this.user?.country
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }, 
    methods: {
        handleScroll() {
            this.isScrolled = window.scrollY > 70; // Adjust threshold as needed
        },
        handleImageError(event) {
            event.target.src = this.default_img; // Ensure `default_img` is defined in your data or props
        },
        logout() {
            store.dispatch('auth/Logout')
        },
        changeRegion(region){
            let formData = new FormData()
            formData.append('inp[country]', region)
            formData.append('id', cookies.get('token'))
            store.dispatch('auth/SaveUser', formData).then(() => {
                // store.dispatch('auth/GetUserData')
                window.location.reload()
            })
        },
        changeRole(role){
            let formData = new FormData()
            formData.append('inp[user_role]', role)
            formData.append('id', cookies.get('token'))
            store.dispatch('auth/SaveUser', formData).then(() => {
                store.dispatch('auth/GetUserData')
                if(role == 'service_provider'){
                    this.$router.push('/bussiness')
                } else {
                    this.$router.push('/homepage')
                }
                // window.location.reload()
            })
        }
    },
    data() {
        return {
            open: false,
            isScrolled: false,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png',
            countries : [
                { slug: 'indonesia', name : 'Indonesia', 'flag': "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/255px-Flag_of_Indonesia.svg.png"},
                { slug: 'singapore', name : 'Singapore', 'flag': "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg"},
                { slug: 'thailand', name : 'Thailand', 'flag': "https://cdn.britannica.com/38/4038-004-111388C2/Flag-Thailand.jpg"},
            ],
            roles: [
                { slug: 'service_provider', name: 'Service Provider', icon: 'ti ti-truck-delivery'},
                { slug: 'pet_owner', name: 'Pet Owner', icon: 'bx bxs-dog'},
            ]
        }
    }
}

</script>

<style scoped>
.dropdown-toggle::after {
    display: none !important;
}

.home-link {
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.home-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    /* Adjust height as needed */
    background-color: #FFAD32;
    transition: width 0.3s ease-in-out;
}

.home-link:hover::after {
    width: 100%;
}

.desktop-nav {
    position: relative;
    transition: all 0.3s ease;
}

.desktop-nav.scrolled {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    /* Adjust color as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>